<template>
    <a :href="blog.path" class="blog-list-item">
        <div class="type">
            <Typography as="h2" variant="h3-display" class="title">{{ blog.title }}</Typography>
            <RichTextElements :elements="blog.summary?.dek" class="dek" bodyStyle="serif-large" />
        </div>
        <Image :src="blogAdImage" />
    </a>
</template>

<script setup>
const props = defineProps({
    blog: {
        type: Object,
        required: true,
    },
});

const blogAdImage = computed(() => {
    if (props.blog.title === 'Diwan') {
        return '/static/media/images/Diwan-Landing%20header.png';
    } else if (props.blog.title === 'Carnegie Politika') {
        return '/static/media/images/Politika-Landing%20header.png';
    } else if (props.blog.title === 'China Financial Markets') {
        return '/static/media/images/CFM-Landing%20header.png';
    } else if (props.blog.title === 'Sada') {
        return '/static/media/images/Sada-Landing%20header.png';
    } else if (props.blog.title === 'Strategic Europe') {
        return '/static/media/images/Strategic%20Europe-Landing%20header.png';
    } else if (props.blog.title === 'Emissary') {
        return '/static/media/images/Emmisary-Landing header.png';
    } else {
        return props.blog?.summary?.heroBlog?.heroImage.url;
    }
});
</script>

<style lang="scss" scoped>
.blog-list-item {
    width: 100%;
    @include grid($layout: '50');
    :deep(img.image) {
        margin: 0 auto;
        max-height: 80%;
    }
}
.title {
    color: color(blue, default, 0.8);
}
.dek {
    color: color(offnoir, default, 0.7);
}
.type {
    @include media-query(full) {
        padding: 0 6rem;
    }
    @include media-query(mobile) {
        padding: 3.2rem 2.4rem;
    }
    margin: auto 0;
}
</style>
